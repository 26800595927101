import React, { useEffect, useRef, useState } from "react";
import "./App.css";
import Experience from "./experiences/Experience";
import Layout from "./components/Layout";
import "./styles/layout.css"
import "./index.css"
import Welcome from "./components/Screens/Welcome";
import Profile from "./components/Screens/Profile";

import ChooseZense from "./components/Screens/ChooseZense";
import ChooseNaturescape from "./components/Screens/ChooseNaturescape";
import ExperienceSummary from "./components/Screens/ExperienceSummary";
import ChooseBreathwork from "./components/Screens/ChooseBreathwork";
import Review from "./components/Screens/Review";
import ChooseTouchpad from "./components/Screens/ChooseTouchpad";
import ScormProvider from 'react-scorm-provider';
import { Routes, Route, Navigate } from 'react-router-dom'
import { setGlobalAppState } from "./components/AppState";
import { SoundscapeId, SenseId, AudioDurationSecs, UserActivityType } from "./components/Data/constants";
import { getConfiguration, isDebug } from "./components/Util/ConfigHelper";
import { AuthProvider, CustomerId, DeploymentType, isUseLicenses } from "./components/Data/Models/configuration";
import { RouteId } from "./Routes";
import { handleBackNavigation, handleNavigationfromParams, queryParams } from "./components/Util/SearchParamHelper";
import { listenToFullScreenEnter } from "./components/Util/ScreenUtil";
import PageNotFound from "./components/Screens/PageNotFound";
import AfterExperience from "./components/Screens/AfterExperience";
import { getExperienceAnalyticsHolderDefault } from "./components/Util/ExperienceAnalyticsHelper";
import Login from "./components/Screens/Login";
import { LOGGER, LogLevel } from "./components/Util/Logger";
import { ZensoryModule } from "./ZensoryModule";
import { isNullOrEmpty } from "./components/Data/Util/util";
import { DEEP_LINK_PARAM_AUDIO_DURATION_SECS, DEEP_LINK_PARAM_AUTH_TOKEN, DEEP_LINK_PARAM_AUTO_PLAY, DEEP_LINK_PARAM_EXPERIENCE_ID, DEEP_LINK_PARAM_MOOD_ID, DEEP_LINK_PARAM_SOURCE, DEEP_LINK_PARAM_SUB_ZENSE_ID, DEEP_LINK_PARAM_ZENSE_ID } from "./components/Util/DeepLinkUtil";
import SignUp from "./components/Screens/SignUp";
import ForgotPassword from "./components/Screens/ForgotPassword";
import Subscribe from "./components/Screens/Subscribe";
import AuthErrorPopup from "./components/AuthErrorPopup";
import ManageSubcription from "./components/Screens/ManageSubscription";
import { checkUserLicenses } from "./components/Impl/Licenses/LicenseHelper";
import LoadingFullScreen from "./components/Screens/LoadingFullScreen";

const App = () => {
  const [start, setStart] = useState(false);
  const [restart, setRestart] = useState(false);
  const [chooseZense, setChooseZense] = useState(false);
  const [chooseNaturescape, setChooseNaturescape] = useState(false);
  const [chooseBreathwork, setChooseBreathwork] = useState(false);
  const [chooseTouchpad, setChooseTouchpad] = useState(false);
  const [experienceSummary, setExperienceSummary] = useState(false);
  const [review, setReview] = useState(false);
  const [afterExperience, setAfterExperience] = useState(false);
  const [beforeRating, setBeforeRating] = useState(0);
  const [afterRating, setAfterRating] = useState();
  const [expired, setExpired] = useState(false);

  const [signupPage, setSignupPage] = useState(false);
  const [loginPage, setLoginPage] = useState(false);
  const [profilePage, setProfilePage] = useState(false);
  const [forgotPasswordPage, setForgotPasswordPage] = useState(false);

  // MoodId enum to track the Mood selected for the Experience
  const [mood, setMood] = useState("");
  // SenseId enum
  const [zense, setZense] = useState("");
  // SubSenseId enum
  const [subZense, setSubZense] = useState("");
  // For Sound, this is "soundscapeId". For Breath, this is the type of Breath experience (eg. audio/visual)
  const [experience, setExperience] = useState(SoundscapeId.NoNature);
  // Always an AudioDurationSecs enum
  const [timeLimit, setTimeLimit] = useState(AudioDurationSecs.THREE_3_MINS);
  // Duration of the Experience
  const [experienceDuration, setExperienceDuration] = useState(0);
  // Signed-in User object
  const [user, setUser] = useState(null);
  // Store Full Screen state outside of Experience so we keep track in between Experiences
  const [isFullScreen, setIsFullScreen] = useState(false);
  // Track if we came from a deep link
  const [isDeepLink, setIsDeepLink] = useState(false);
  // Set to TRUE when the user is signing up, so we know to wait for that to complete before we load the user
  const [isSigningUp, setIsSigningUp] = useState(false);
  // Whether a user is logged in, set it to "undefined" to prevent redirection before authentication
  const [isLoggedIn, setIsLoggedIn] = useState(undefined);
  // track meta data about the experience in 1 holder object
  const [getExperienceAnalyticsHolder, setExperienceAnalyticsHolder] = useState(
    getExperienceAnalyticsHolderDefault()
  );
  // Used to track native browser back button clicks (for ExperienceDeepLinkOnly mode)
  const [isBackNavigation, setIsBackNavigation] = useState(false);

  const [licenceError, setLicenseError] = useState();
  const [showLicenseErrorPopup, setShowLicencesErrorPop] = useState();

  const [isLoading, setIsLoading] = useState(true);

  const backNavRef = useRef();
  backNavRef.current = isBackNavigation;

  const searchParams = queryParams;
  const [urlParamMoodId, setUrlParamMoodId] = useState(searchParams[DEEP_LINK_PARAM_MOOD_ID]);
  const [urlParamZenseId, setUrlParamZenseId] = useState(searchParams[DEEP_LINK_PARAM_ZENSE_ID]);
  const [urlParamSubZenseId, setUrlParamSubZenseId] = useState(searchParams[DEEP_LINK_PARAM_SUB_ZENSE_ID]);
  const [urlParamExperienceId, setUrlParamExperienceId] = useState(searchParams[DEEP_LINK_PARAM_EXPERIENCE_ID]);
  const [urlParamAudioDurationSecs, setUrlParamAudioDurationSecs] = useState(searchParams[DEEP_LINK_PARAM_AUDIO_DURATION_SECS]);
  const [urlParamAutoPlay, setUrlParamAutoPlay] = useState(searchParams[DEEP_LINK_PARAM_AUTO_PLAY]);
  const [urlParamAuthToken, setUrlParamAuthToken] = useState(searchParams[DEEP_LINK_PARAM_AUTH_TOKEN]);
  const [urlParamSource, setUrlParamSource] = useState(searchParams[DEEP_LINK_PARAM_SOURCE]);

  // Dynamically override the og:url header value based on the config
  useEffect(() => {
    const config = getConfiguration();
    const url = config.authorisedDomains && config.authorisedDomains.length > 0
      ? config.authorisedDomains[0]
      : "https://zensory.web.app";
    const selctor = document.querySelector('meta[property="og:url"]');
    if (selctor) {
      selctor.setAttribute("content", url);
    }
  });

  // Handle first check on if user is logged in
  useEffect(() => {
    async function hasUser() {
      const config = getConfiguration();
      
      // if no auth, go straight to the app
      if (config.authProvider === AuthProvider.None) {
        LOGGER.log(LogLevel.DEBUG, `AUTH: No auth provider, skipping auth check`);
        setIsLoading(false);
        // must treat no auth as user is logged in
        setIsLoggedIn(true);
        return;
      }

      const forceAuth = getConfiguration().forceAuth;
      const hasUser = await ZensoryModule.getAuth().hasUser(true).then(res => {
        return res;
      });

      // if we are forcing auth and we don't have a user, then redirect to sign up
      if (!hasUser && forceAuth) {
        LOGGER.log(LogLevel.DEBUG, `AUTH: Not logged in, forcing auth`);
        setSignupPage(true);
      } else if (hasUser && (isUseLicenses() || config.autoRedeemCode)) {
        LOGGER.log(LogLevel.DEBUG, `AUTH: Logged in, checking licenses`);
        try {
          checkUserLicenses(user, []);
        } catch (error) {
          setLicenseError(error.message);
          setShowLicencesErrorPop(true);
        }
      } else if (!hasUser) {
        // clear the error handler
        LOGGER.log(LogLevel.DEBUG, `AUTH: No user, clearing error handler`);
        ZensoryModule.getErrorHandler().destroy();
      }
      
      LOGGER.log(LogLevel.DEBUG, `AUTH: hasUser=${hasUser}`);
      setIsLoggedIn(hasUser);
      setIsLoading(false);
    }
    hasUser();
  }, []);
  
  // Handles native browser back button clicks when in ExperienceDeepLinkOnly mode
  useEffect(() => {
    handleBackNavigation(setIsBackNavigation, window, backNavRef);
  },[setIsBackNavigation]);

  // Get users active licesnes
  // if array of active licneses is 0
  // we redirect to subscribe page
  useEffect(() => {
    if (isLoggedIn === true && !isNullOrEmpty(user) && (isUseLicenses() || getConfiguration().autoRedeemCode)) {
      try {
        checkUserLicenses(user, []);
      } catch (error) {
        setLicenseError(error.message);
        setShowLicencesErrorPop(true);
      }

    } else {
      // clear the error handler
      ZensoryModule.getErrorHandler().destroy();
    }

  }, [isLoggedIn, user]);

  const getUserId = () => {
    if (config.customerId === CustomerId.Brigantia && config.authProvider === AuthProvider.None) {
      return `BrigantiaUserGlobal`;
    } else if (user) {
      return user.userId;
    } else {
      return undefined;
    }
  }

  // Handle Firebase authentication, app open user activity tracking
  // and adding user device details to db when
  // we get set to logged in, or clear when logged out
  useEffect(() => {
    async function trackUserActivity() {
      await ZensoryModule.getAnalytics().trackUserActivity(
        getUserId(),
        {
          type: UserActivityType.AppOpen,
          timestampMillis: new Date().getTime(),
        }
      );
    }

    // for Brigantia, if they are disabling auth, we need to track all users under 1 user ID
    const config = getConfiguration();
    if (config.customerId === CustomerId.Brigantia && config.authProvider === AuthProvider.None) {
      // Ensure we identify the user using their User ID
      ZensoryModule.getAnalytics().identify(getUserId());

      trackUserActivity();
      return;
    }

    if (isLoggedIn === true && !isNullOrEmpty(user)) {
      // track the user in the error handler
      ZensoryModule.getErrorHandler().init(user);

      // Ensure we identify the user using their User ID
      ZensoryModule.getAnalytics().identify(user.userId);
      
      const updates = {};
      if (config.deploymentType === DeploymentType.Scorm) {
        updates.isScorm = true;
      }
      if (config.deploymentType === DeploymentType.Web) {
        updates.isWeb = true;
      }
      ZensoryModule.getAnalytics().updateUserProps(updates);

      async function addUserDevice() {
        await ZensoryModule.getData().addUserDevice();
      }
      
      addUserDevice();
      trackUserActivity();

    } else {
      // clear the error handler
      ZensoryModule.getErrorHandler().destroy();
    }
  }, [isLoggedIn, user]);

  // Handle Firebase authentication with auth token and then URL params

  useEffect(() => {
    async function handleUrlParams() {
      // if we have an auth token, attempt the log in
      var isSignedIn = false;
      if (urlParamAuthToken) {
        const hasUser = await ZensoryModule.getAuth().getCurrentUserWithObserver();
        if (hasUser) {
          // TODO: should we ask user if they want to proceed with currently authed user?
        } else {
          isSignedIn = await ZensoryModule.getAuth().signInWithCustomToken(urlParamAuthToken);
        }
        setUrlParamAuthToken(null); // clear the token after use
      } else {
        isSignedIn = isLoggedIn === true;
      }
  
      // if we are signed in, then handle the URL params
      if (isSignedIn) {
        handleNavigationfromParams(
          urlParamMoodId,
          urlParamZenseId,
          urlParamSubZenseId,
          urlParamExperienceId,
          urlParamAudioDurationSecs,
          urlParamAutoPlay,
          urlParamSource
        );

        // clear the values after being used
        setUrlParamMoodId(null);
        setUrlParamZenseId(null);
        setUrlParamSubZenseId(null);
        setUrlParamExperienceId(null);
        setUrlParamAudioDurationSecs(null);
        setUrlParamAutoPlay(null);
        setUrlParamSource(null);
      }
    };
    if (!isSigningUp) {
      handleUrlParams();
    }

  }, [urlParamSubZenseId, urlParamAuthToken, urlParamMoodId, urlParamZenseId, urlParamExperienceId, urlParamAudioDurationSecs, urlParamAutoPlay, urlParamSource, isLoggedIn, user, isSigningUp]);

  // Listen to the user entering/exiting full screen from the browser native controls
  // here in case the user changes it outside of an Experience (where we expose the toggle button)
  useEffect(() => {
    listenToFullScreenEnter((isFullScreen) => {
      setIsFullScreen(isFullScreen);
    });
  }, []);

  // set all the states into the global app state
  setGlobalAppState({
    setStart,
    setRestart,
    setChooseZense,
    setChooseNaturescape,
    setChooseBreathwork,
    setChooseTouchpad,
    setExperienceSummary,
    setReview,
    setAfterExperience,
    setBeforeRating,
    setAfterRating,
    setExpired,
    setMood,
    getMood: () => { return mood; },
    setZense,
    setSubZense,
    setIsDeepLink,
    getIsDeepLink: () => { return isDeepLink; },
    setIsLoggedIn,
    isSigningUp: () => { return isSigningUp; },
    setIsSigningUp,
    setUser,
    getUser: () => {return user; },
    setExperience,
    setTimeLimit,
    setExperienceDuration,
    setExperienceAnalyticsHolder,
    getExperienceAnalyticsHolder: () => { return getExperienceAnalyticsHolder; },
    setSignupPage,
    setLoginPage,
    setProfilePage,
    setForgotPasswordPage,
  });

  const debug = isDebug();
  const config = getConfiguration();
  const deploymentType = config.deploymentType;

  const getSignupComponent = () => {
    return <SignUp />;
  }

  const getLoginComponent = () => {
    return <Login
      isLoggedIn={isLoggedIn}
      setIsLoggedIn={setIsLoggedIn}
    />;
  }

  const getProfileComponent = () => {
    return <Profile />;
  }

  const getForgotPasswordComponent = () => {
    return <ForgotPassword />;
  }

  const getExperienceComponent = () => {
    return <Experience
      mood={mood}
      zense={zense}
      subZense={subZense}
      experience={experience}
      timeLimit={timeLimit}
      expired={expired}
      restart={restart}
      experienceDuration={experienceDuration}
      isFullScreen={isFullScreen}
      setIsFullScreen={setIsFullScreen}
      setRestart={setRestart}
      setExpired={setExpired}
      setExperience={setStart}
      setExperienceDuration={setExperienceDuration}
      setReview={setReview}
      onClose={setReview}
    />;
  }

  const getChooseZenseComponent = () => {
    return <ChooseZense
      mood={mood}
      zense={zense}
      subZense={subZense}
      onChooseZense={setChooseZense}
      onChooseNaturescape={setChooseNaturescape}
      onChooseBreathwork={setChooseBreathwork}
      onChooseTouchpad={setChooseTouchpad}
      setSubZense={setSubZense}
      setExperience={setExperience}
      setZense={setZense}
      setMood={setMood}
    />;
  }

  const getChooseNaturescapeComponent = () => {
    return <ChooseNaturescape
      onChooseNaturescape={setChooseNaturescape}
      onexperienceSummary={setExperienceSummary}
      experience={experience}
      timeLimit={timeLimit}
      mood={mood}
      zense={zense}
      subZense={subZense}
      setSubZense={setSubZense}
      setExperience={setExperience}
      setTimeLimit={setTimeLimit}
      onClose={setChooseZense}
    />;
  }

  const getChooseBreathworkComponent = () => {
    return <ChooseBreathwork
      onChooseBreathwork={setChooseBreathwork}
      onexperienceSummary={setExperienceSummary}
      mood={mood}
      zense={zense}
      subZense={subZense}
      experience={experience}
      timeLimit={timeLimit}
      setSubZense={setSubZense}
      setExperience={setExperience}
      setTimeLimit={setTimeLimit}
      onClose={setChooseZense}
    />;
  }

  const getChooseTouchpadComponent = () => {
    return <ChooseTouchpad
      onChooseTouchpad={setChooseTouchpad}
      onexperienceSummary={setExperienceSummary}
      mood={mood}
      zense={zense}
      subZense={subZense}
      experience={experience}
      timeLimit={timeLimit}
      setSubZense={setSubZense}
      setExperience={setExperience}
      setTimeLimit={setTimeLimit}
      onClose={setChooseZense}
    />;
  }

  const getExperienceSummaryComponent = () => {
    return <ExperienceSummary
      start={start}
      onStart={setStart}
      mood={mood}
      zense={zense}
      subZense={subZense}
      timeLimit={timeLimit}
      experience={experience}
      beforeRating={beforeRating}
      setBeforeRating={setBeforeRating}
      onexperienceSummary={setExperienceSummary}
      onClose={zense === SenseId.Breath ? setChooseBreathwork : zense === SenseId.Touch ? setChooseTouchpad : setChooseNaturescape}
    />;
  }

  const getReviewComponent = () => {
    return <Review
      mood={mood}
      zense={zense}
      subZense={subZense}
      experience={experience}
      timeLimit={timeLimit}
      beforeRating={beforeRating}
      afterRating={afterRating}
      experienceDuration={experienceDuration}
      setMood={setMood}
      setExperience={setExperience}
      setZense={setZense}
      setTimeLimit={setTimeLimit}
      setExpired={setExpired}
      setRestart={setRestart}
      setBeforeRating={setBeforeRating}
      setAfterRating={setAfterRating}
      setAfterExperience={setAfterExperience}
      onReview={setReview}
    />;
  }

  const getAfterExperienceComponent = () => {
    return <AfterExperience
      mood={mood}
      zense={zense}
      subZense={subZense}
      experience={experience}
      timeLimit={timeLimit}
      beforeRating={beforeRating}
      afterRating={afterRating}
      experienceDuration={experienceDuration}
      setMood={setMood}
      setExperience={setExperience}
      setZense={setZense}
      setTimeLimit={setTimeLimit}
      setExpired={setExpired}
      setRestart={setRestart}
      setBeforeRating={setBeforeRating}
      setAfterRating={setAfterRating}
      onReview={setReview}
    />;
  }

  const getWelcomeComponent = () => {
    return <Welcome
      onMood={setMood}
      onChooseZense={setChooseZense}
    />;
  }

  // For Scorm, return the state-based system that is wrapped in the <ScormProvider> tags
  if (deploymentType === DeploymentType.Scorm) {
    return (
      <ScormProvider debug={debug}>
        <Layout mood={mood}>
          <div className="container">
            {
              isLoading
                ? <LoadingFullScreen />
              : signupPage
                ? getSignupComponent()
              : loginPage
                ? getLoginComponent()
              : profilePage
                ? getProfileComponent()
              : forgotPasswordPage 
                ? getForgotPasswordComponent()
              : start
                ? getExperienceComponent()
              : chooseZense
                ? getChooseZenseComponent()
              : chooseNaturescape
                ? getChooseNaturescapeComponent()
              : chooseBreathwork
                ? getChooseBreathworkComponent()
              : chooseTouchpad
                ? getChooseTouchpadComponent()
              : experienceSummary
                ? getExperienceSummaryComponent()
              : afterExperience
                ? getAfterExperienceComponent()
              : review || expired
                ? getReviewComponent()
              : getWelcomeComponent()
            }
          </div>
        </Layout>
      </ScormProvider>
    );

    // else for Web, return Route and state based without the <ScormProvider> tags
  } else if (deploymentType === DeploymentType.Web) {
    return (
      <Layout mood={mood}>
        <div className="container">
          {showLicenseErrorPopup ? (
            <AuthErrorPopup
              setShowAuthErrorPopup={setShowLicencesErrorPop}
              errorMessage={licenceError}
            />
          ) : null}
          <Routes>
            <Route
              path={`/${RouteId.Experience}`} 
              element={start
                ? getExperienceComponent()
                : <Navigate to="/" />
              }
            />
            <Route
              path={`/${RouteId.ChooseZense}`}
              element={chooseZense || mood !== ""
                ? getChooseZenseComponent()
                : <Navigate to="/" />
              }
            />
            <Route
              path={`/${RouteId.ChooseNaturescape}`}
              element={chooseNaturescape || zense !== ""
                ? getChooseNaturescapeComponent()
                : <Navigate to="/" />
              }
            />
            <Route
              path={`/${RouteId.ChooseBreathwork}`}
              element={chooseBreathwork || zense !== ""
                ? getChooseBreathworkComponent()
                : <Navigate to="/" />
              }
            />
            <Route
              path={`/${RouteId.ChooseTouchpad}`}
              element={chooseTouchpad || zense !== ""
                ? getChooseTouchpadComponent()
                : <Navigate to="/" />
              }
            />
            <Route 
              path={`/${RouteId.ExperienceSummary}`}
              element={getExperienceSummaryComponent()}
            />
            <Route
              path={`/${RouteId.Review}`}
              element={review || expired
                ? getReviewComponent()
                : <Navigate to="/" />
              }
            />
            <Route
              path={`/${RouteId.AfterExperience}`}
              element={afterExperience
                ? getAfterExperienceComponent()
                : <Navigate to="/" />
              }
            />
            <Route
              path={`/${RouteId.SignUp}`}
              element={getSignupComponent()}
            />
            <Route
              path={`/${RouteId.ForgotPassword}`}
              element={getForgotPasswordComponent()}
            />
            <Route
              path={`/${RouteId.Login}`}
              element={getLoginComponent()}
            />
            <Route
              path={`/${RouteId.Profile}`}
              element={isLoggedIn === true
                ? getProfileComponent()
                : isLoggedIn === false
                  ? <Navigate to={`/${RouteId.Login}`} />
                  : <></>
              }
            />
            <Route
              path="/"
              element={isLoggedIn === true
                ? getWelcomeComponent()
                : isLoggedIn === false
                  ? <Navigate to={`/${RouteId.Login}`} />
                  : <></>
              }
            />
             <Route path={RouteId.Subscribe} element={
              isLoggedIn === true
              ? <Subscribe
              />
              : isLoggedIn === false
              ? <Navigate to={`/${RouteId.Login}`} />
              : <></>
            } />
              <Route path={RouteId.ManageSubscription} element={
              isLoggedIn === true
              ? <ManageSubcription
              />
              : isLoggedIn === false
              ? <Navigate to={`/${RouteId.Login}`} />
              : <></>
            } />
            <Route path={"*" || RouteId.NotAuthorized} element={
              <PageNotFound />
            } />
          </Routes>
        </div>
      </Layout>
    )
  }
};

export default App;
