import { getConfiguration } from "../../Util/ConfigHelper";
import { LogLevel } from "../../Util/Logger";
import { AudioDurationSecs, EnvId, LinkId, MoodId, Platform, SenseId, SoundscapeId, SubSenseId, TouchpadId } from "../constants";

/**
 * Configuration handles parsing the config.json and casting it to the correct interface/class type and
 * all sub-types
 * 
 * Notes:
 *      1. Some config values are optional, if no override is provided, 
 *         then the default value will be used from ConfigurationDefault in constants.ts
 *      2. Any new config values must be added to the Configuration interface and the ConfigurationOverride
 */
export interface Configuration {
    configId: string;
    version: string;
    deploymentType: DeploymentType;
    customerId: CustomerId;
    envId: EnvId;
    authProvider: AuthProvider;
    authMethods: {[authMethod in AuthMethod]: boolean};
    isWorkEmailOnly?: boolean;
    databaseType: DatabaseType;
    analyticMethods: {[analyticsMethod in AnalyticsMethod]: boolean};
    analyticsProps?: {[key: string]: string};
    crashTrackingMethods: {[crashMethod in CrashTrackingMethod]: boolean};
    authorisedDomains: string[];
    logging: {
        isEnabled: boolean;
        logLevel: LogLevel;
    };
    isExperienceDeepLinkOnly: boolean;
    contentConfig: {[moodId in MoodId]?: ExperienceMoodConfig};
    endExperienceConfig: BaseEndExperienceConfig;
    devSettings: DevSettingsConfig;
    links: { [linkId in LinkId]: string };
    hideProfileButton?: boolean;
    useLicenses?: boolean;
    autoRedeemCode: string | null;
    platformOverride: Platform | null;
    forceAuth?: boolean;
    subscriptionConfig?: {
        isHideManageSubscription: boolean;
    };
    // Any new config values must also be added to the Configuration interface, the ConfigurationOverride and ConfigurationDefault
}

/**
 * Used to override the default configuration values
 */
export interface ConfigurationOverride {
    // Required
    configId: string;
    deploymentType: DeploymentType;
    customerId: CustomerId;
    authorisedDomains: string[];

    // Optional overrides
    envId?: EnvId;
    version?: string;
    authProvider?: AuthProvider;
    authMethods?: {[authMethod in AuthMethod]: boolean};
    isWorkEmailOnly?: boolean;
    databaseType?: DatabaseType;
    analyticMethods?: {[analyticsMethod in AnalyticsMethod]: boolean};
    analyticsProps?: {[key: string]: string};
    crashTrackingMethods?: {[crashMethod in CrashTrackingMethod]: boolean};
    logging?: {
        isEnabled: boolean;
        logLevel: LogLevel;
    };
    isExperienceDeepLinkOnly?: boolean;
    contentConfig?: {[moodId in MoodId]?: ExperienceMoodConfig};
    endExperienceConfig?: BaseEndExperienceConfig;
    devSettings?: DevSettingsConfig;
    links?: {[linkId in LinkId]: string};
    hideProfileButton?: boolean;
    useLicenses?: boolean;
    autoRedeemCode?: string | null;
    platformOverride?: Platform | null;
    forceAuth?: boolean;
    subscriptionConfig?: {
        isHideManageSubscription: boolean;
    };
}

export interface BaseExperienceConfig {
    isShow: boolean;
}

export interface ExperienceMoodConfig extends BaseExperienceConfig {
    zenses: {[zenseId in SenseId]?: ExperienceZenseConfig};
}

export interface ExperienceZenseConfig extends BaseExperienceConfig {
    order?: number;
    subZenses: {[subSenseId in SubSenseId]?: ExperienceSubZenseConfig};
}

export interface MoodZenseConfig extends BaseExperienceConfig {
    audioDurations: {[audioDurationSecs in AudioDurationSecs]?: boolean};
}

export interface MoodZenseBreathConfig extends MoodZenseConfig {
}

export interface MoodZenseSoundConfig extends MoodZenseConfig {
    isAllSoundscapes: boolean; // default TRUE
    soundscapeIds: {[soundscapeId in SoundscapeId]?: boolean};
}

export interface MoodZenseTouchConfig extends MoodZenseConfig {
    touchpadIds?: {[touchpadId in TouchpadId]?: boolean};
}

export type ExperienceSubZenseConfig = MoodZenseBreathConfig | MoodZenseSoundConfig | MoodZenseTouchConfig;

export enum CustomerId {
    TheZensory = "The Zensory",
    KnowBe4 = "KnowBe4",
    Brigantia = "Brigantia",
}

export enum DeploymentType {
    Web = "web",
    Scorm = "scorm",
}

export enum AuthProvider {
    None = "none",
    Firebase = "firebase"
}

export enum AuthMethod {
    Anonymous = "anonymous",
    EmailPassword = "emailPassword",
}

export enum DatabaseType {
    Local = "local",
    Firebase = "firebase",
}

export enum AnalyticsMethod {
    Scorm = "scorm",
    Firebase = "firebase",
    Mixpanel = "mixpanel",
}

export enum CrashTrackingMethod {
    Sentry = "sentry",
    Firebase = "firebase",
}

export enum EndExperienceType {
    None = "none",
    ContactForm = "contact",
    Survey = "survey"
}

export enum SurveyPlatform {
    GoogleForm = "googleForm",
    TypeForm = "typeForm",
    SurveyMonkey = "surveyMonkey"
}

type NoneEndExperienceType = EndExperienceType.None;
type ContactFormEndExperienceType = EndExperienceType.ContactForm;
type SurveyEndExperienceType = EndExperienceType.Survey;

export interface BaseEndExperienceConfig {
    type: EndExperienceType;
    pageTitleText: string;
    titleText?: string;
    subTitleText?: string;
    isSingleExperience: boolean;
    appDownloadConfig: AppDownloadConfig;
}

export interface AppDownloadConfig {
    isShow: boolean;
    title?: string;
    subTitle?: string;
    androidButtonUrl?: string;
    iosButtonUrl?: string;
}

export interface NoneEndExperienceConfig extends BaseEndExperienceConfig {
    type: NoneEndExperienceType;
}

export interface SurveyEndExperienceConfig extends BaseEndExperienceConfig {
    type: SurveyEndExperienceType;
    surveyPlatform: SurveyPlatform;
    surveyUrl: string;
}

export interface ContactEndExperienceConfig extends BaseEndExperienceConfig {
    type: ContactFormEndExperienceType;
    // title and sub title are required
    titleText: string;
    subTitleText: string;
    buttonText: string;
    buttonClickUrl: string;
}

export function getSurveyEndExperienceConfig(): SurveyEndExperienceConfig {
    const endExperienceConfig = getConfiguration().endExperienceConfig;
    return endExperienceConfig as SurveyEndExperienceConfig;
}

export function getContactEndExperienceConfig(): ContactEndExperienceConfig {
    const endExperienceConfig = getConfiguration().endExperienceConfig;
    return endExperienceConfig as ContactEndExperienceConfig;
}

export function getNoneEndExperienceConfig(): NoneEndExperienceConfig {
    const endExperienceConfig = getConfiguration().endExperienceConfig;
    return endExperienceConfig as NoneEndExperienceConfig;
}

export type EndExperienceConfig = SurveyEndExperienceConfig | NoneEndExperienceConfig | ContactEndExperienceConfig;

export interface DevSettingsConfig {
    // password used to validate calls to the send email Cloud Function
    sendEmailPassword: string;
}

export interface LinkConfig {
    privacyPolicy: string;
    terms: string;
}

export function isHideProfileButton(): boolean {
    const config = getConfiguration();
    return config.hideProfileButton === true || config.authProvider === AuthProvider.None;
}

export function isUseLicenses(): boolean {
    const config = getConfiguration();
    return config.useLicenses === true;
}